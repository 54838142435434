var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1 ",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStepOne)}}},[_c('upload-avatar',{staticClass:"my-3",attrs:{"current":_vm.form.avatar},model:{value:(_vm.form.avatar),callback:function ($$v) {_vm.$set(_vm.form, "avatar", $$v)},expression:"form.avatar"}}),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-1/2 pr-2"},[_c('text-input',{attrs:{"rules":"required","label":"Name","placeholder":"Organization name"},on:{"input":_vm.changeValues},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('text-input',{attrs:{"rules":"required","label":"Phone number","placeholder":"Phone number","input_type":"phone"},on:{"input":_vm.changeValues},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}})],1)]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-1/2 pr-2"},[_c('text-input',{attrs:{"placeholder":"Alias name","rules":"required","label":"Alias"},on:{"input":_vm.changeValues},model:{value:(_vm.form.alias),callback:function ($$v) {_vm.$set(_vm.form, "alias", $$v)},expression:"form.alias"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Country *"}}),_c('t-rich-select',{attrs:{"options":_vm.countries,"valueAttribute":"id","textAttribute":"name","placeholder":"Select a country","variant":{ danger: !!errors[0] }},on:{"input":_vm.changeValues},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-1/2 pr-2"},[_c('ValidationProvider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"City *"}}),_c('t-rich-select',{attrs:{"options":_vm.cities,"valueAttribute":"id","textAttribute":"name","placeholder":_vm.getCityPlaceholder,"disabled":_vm.getCityDisabledState,"variant":{ danger: !!errors[0] }},on:{"update:options":function($event){_vm.cities=$event},"update:placeholder":function($event){_vm.getCityPlaceholder=$event},"input":_vm.changeValues},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('text-input',{attrs:{"placeholder":"Zip code","rules":"required","label":"Zip Code"},on:{"input":_vm.changeValues},model:{value:(_vm.form.zip_code),callback:function ($$v) {_vm.$set(_vm.form, "zip_code", $$v)},expression:"form.zip_code"}})],1)]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-1/2 pr-2"},[_c('ValidationProvider',{attrs:{"name":"Default fleet","rules":_vm.getDefaultFleetValidationRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Default Fleet"}}),_c('t-rich-select',{attrs:{"placeholder":_vm.getDefaultFleetPlaceholder,"options":_vm.getDefaultFleetOptions,"valueAttribute":"id","textAttribute":"name","variant":{ danger: !!errors[0] },"tabindex":"0"},on:{"input":_vm.changeValues},model:{value:(_vm.form.default_fleet),callback:function ($$v) {_vm.$set(_vm.form, "default_fleet", $$v)},expression:"form.default_fleet"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Type *"}}),_c('t-rich-select',{attrs:{"placeholder":"Organization Type","options":_vm.getOrganizationTypeOptions,"valueAttribute":"id","textAttribute":"name","hideSearchBox":true,"variant":{ danger: !!errors[0] }},on:{"input":_vm.changeValues},model:{value:(_vm.form.organization_category),callback:function ($$v) {_vm.$set(_vm.form, "organization_category", $$v)},expression:"form.organization_category"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',{staticClass:"w-full"},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Address *"}}),_c('t-textarea',{attrs:{"rules":"required"},on:{"input":_vm.changeValues},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"domain-title"},[_c('span',[_vm._v("Domain")])]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-1/2 pr-2"},[_c('text-input',{attrs:{"placeholder":"www.example.com","rules":"required","label":"Admin Domain"},on:{"input":_vm.changeValues},model:{value:(_vm.form.admin_domain),callback:function ($$v) {_vm.$set(_vm.form, "admin_domain", $$v)},expression:"form.admin_domain"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('text-input',{attrs:{"placeholder":"www.example.com","rules":"required","label":"App Domain"},on:{"input":_vm.changeValues},model:{value:(_vm.form.domain),callback:function ($$v) {_vm.$set(_vm.form, "domain", $$v)},expression:"form.domain"}})],1)]),_c('button',{ref:"stepOneSubmitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }