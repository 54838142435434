<template>
  <div @click="toggle">
    <slot><button>Toggle</button></slot>
  </div>
</template>

<script>
export default {
  name: 'SlideOverRightTrigger',
  props: {
    id: {
      required: true,
      type: String,
    },
    direction: {
      required: true,
      type: String,
    },
  },
  mounted: function() {
    // console.log("mounted");
  },
  methods: {
    // fire event
    open(custom = null) {
      let eventName = custom !== null ? custom : this.id
      let c = dispatchEvent(new Event(this.getOpeningEventName(eventName)))
      console.log(c)
      // let o = dispatchEvent(new Event(this.openingEvent));
      //console.log(o)
    },
    close(custom = null) {
      console.log('cancel = ')
      let eventName = custom !== null ? custom : this.id
      dispatchEvent(new Event(this.getClosingEventName(eventName)))
      // let c = dispatchEvent(new Event(this.closingEvent));
      //console.log(c)
    },
    toggle() {
      if (this.direction == 'prev') this.$emit('goPrev')
      else this.$emit('goNext')
      return
    },
    getOpeningEventName(id) {
      return `sor-open-${id}`
    },
    getClosingEventName(id) {
      return `sor-close-${id}`
    },
    getTogglingEventName(id) {
      return `sor-toggle-${id}`
    },
  },
}
</script>

<style></style>
