<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal" class=" vld-parent">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <template v-slot:header>
      <div class="pb-3 panel-title">{{ titleText }}</div>
      <stepper
        :steps="stepStatusArr"
        v-model="currentStep"
        :edit="is_edit"
        ref="stepper"
        :with-divider="false"
        v-on:input="changeTabs"
        v-on:errors="checkErrors"
        v-on:getSteps="getStepsArr"
      >
        <template v-slot:step-1> Profile </template>
        <template v-slot:step-2> Subscription </template>
        <template v-slot:step-3> Settings </template>
        <template v-slot:step-4> Owner </template>
      </stepper>
    </template>

    <template v-slot:footer>
      <div class="mb-2 error-style" v-if="server_error_msg != ''">
        <span>{{ server_error_msg }}</span>
      </div>
      <div class="grid items-center grid-cols-10">
        <div class="col-span-2">
          <slide-over-left-trigger :id="sorId" v-on:cancelEdit="showModal">
            <anchor-button variant="secondary" class="text-center custom-font">
              <span class="font-semibold custom-font">
                Cancel
              </span>
            </anchor-button>
          </slide-over-left-trigger>
        </div>
        <div class="col-span-1"></div>
        <div class="flex justify-end col-span-7">
          <slide-over-toggle-trigger
            v-on:goPrev="goPrevStep"
            direction="prev"
            v-if="isFirst"
            :id="sorId"
          >
            <anchor-button variant="secondary">
              <span class="font-semibold">
                Previous
              </span>
            </anchor-button>
          </slide-over-toggle-trigger>
          <t-button type="submit" class="mx-3" @click="submitStep(currentStep)">
            {{
              !isEditing
                ? 'Save & Continue'
                : currentStep != 4
                ? 'Update'
                : 'Finish'
            }}
          </t-button>
          <slide-over-toggle-trigger
            v-on:goNext="goNextStep"
            direction="next"
            v-if="isLast"
            :id="sorId"
          >
            <anchor-button variant="secondary">
              <span class="font-semibold">
                Next
              </span>
            </anchor-button>
          </slide-over-toggle-trigger>
        </div>
      </div>
    </template>

    <div v-if="errors.length > 0" class="pl-6 text-red-500">
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </div>

    <template v-if="currentStep === 1">
      <add-edit-organization-step-1
        v-on:isValid="isValidFunc"
        v-on:show_error="showError"
        v-on:changedata="changeData"
        ref="step1"
      />
    </template>
    <template v-if="currentStep === 2">
      <add-edit-organization-step-2
        v-on:isValid="isValidFunc"
        v-on:show_error="showError"
        v-on:changedata="changeData"
        ref="step2"
      />
    </template>
    <template v-if="currentStep === 3">
      <add-edit-organization-step-3
        v-on:isValid="isValidFunc"
        v-on:show_error="showError"
        v-on:changedata="changeData"
        ref="step3"
      />
    </template>
    <template v-if="currentStep === 4">
      <add-edit-organization-step-4
        v-on:isValid="isValidFunc"
        v-on:show_error="showError"
        v-on:changedata="changeData"
        ref="step4"
      />
    </template>
    <!-- <div class="fixed z-50 flex overflow-auto pin bg-smoke-light csssssssssssssssss">
    </div> -->
  </slide-over-right>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

import { EventBus } from '@/utils/EventBus'
import { OrganizationConfig } from '@/config/OrganizationConfig'

import SlideOverRight from '@/components/modals/SlideOverRight'
import SlideOverLeftTrigger from '@/components/modals/SlideOverLeftTrigger'
//import SlideOverRightTrigger from "@/components/modals/SlideOverRightTrigger";
import SlideOverToggleTrigger from '@/components/modals/SlideOverToggleTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'

import Stepper from '@/components/stepper/Stepper'

import AddEditOrganizationStep1 from '@/views/organization/AddEditOrganizationStep1'
import AddEditOrganizationStep2 from '@/views/organization/AddEditOrganizationStep2'
import AddEditOrganizationStep3 from '@/views/organization/AddEditOrganizationStep3'
import AddEditOrganizationStep4 from '@/views/organization/AddEditOrganizationStep4'

export default {
  name: 'AddEditOrganization',
  components: {
    SlideOverRight,
    SlideOverLeftTrigger,
    AnchorButton,
    Stepper,
    AddEditOrganizationStep1,
    AddEditOrganizationStep2,
    AddEditOrganizationStep3,
    AddEditOrganizationStep4,
    SlideOverToggleTrigger,
  },
  props: {
    is_edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show_modal: true,
      isLoading: false,
      // editing data
      item: {},
      // The organization id that will be edited
      // organizationId: undefined,
      errors: [],
      sorId: OrganizationConfig.events.sorId,
      // stepper
      steps: 4,
      //made by guang
      isValid: false,
      backup: {},
      stepStatusArr: [
        {
          visited: false,
          disabled: true,
          index: 0,
          value: 1,
        },
        {
          visited: false,
          disabled: true,
          index: 1,
          value: 2,
        },
        {
          visited: false,
          disabled: true,
          index: 2,
          value: 3,
        },
        {
          visited: false,
          disabled: true,
          index: 3,
          value: 4,
        },
      ],
      isFirst: false,
      isLast: false,
      server_error_msg: '',
      isEnd: false,
      changeStep: [null, null, null, null],
    }
  },
  computed: {
    ...mapGetters('organization', [
      'isEditing',
      'currentEditingOrganizationId',
    ]),
    titleText() {
      return this.isEditing ? 'Edit Organization' : 'Add Organization'
    },
    is_editing() {
      return this.isEditing
    },
    actionButtonText: function() {
      console.log('currentStep = ', this.currentStep)
      if (this.currentStep == 3) {
        return 'Finish'
      } else return this.isEditing ? 'Update' : 'Save'
    },
    currentStep: {
      get() {
        return this.$store.getters['organization/currentStep']
      },
      set(updatedStep) {
        this.$store.dispatch('organization/saveStep', {
          step: updatedStep,
        })
      },
    },
    ...mapState({
      step1data: (state) => state.organization.step1,
      step2data: (state) => state.organization.step2,
      step3data: (state) => state.organization.step3,
      step4data: (state) => state.organization.step4,
      // currentStep: state => state.organization.step
    }),
  },
  watch: {
    // organizationId: function(updatedId) {
    // }
  },
  async mounted() {
    // listeners
    EventBus.$on(OrganizationConfig.events.editingData, (item) => {
      // remove old store
      this.changeStep = [null, null, null, null]
      //made by guang
      console.log('current = ', this.currentStep)
      this.$store.dispatch('organization/forgetEditingStep')
      this.$store.dispatch('organization/forgetAllStepsData')
      this.$store.dispatch('organization/forgetEditingOrganizationId')
      // todo: forget last opened step?
      // pass data to step-* & trigger step-* from here
      this.item = item
      if (Object.keys(item).length > 1) {
        this.populateOrganizationStore(this.item)
        this.init('edit')
      } else {
        var result = []
        for (var i = 0; i < this.stepStatusArr.length; i++) {
          var obj = {
            visited: false,
            disabled: true,
            index: i,
            value: i + 1,
          }
          result.push(obj)
        }
        this.stepStatusArr = result
        this.changeInitBottomBtn(0)
        var vm = this
        setTimeout(() => {
          if (vm.$refs.stepper.persist != undefined) {
            vm.$refs.stepper.changeStepsArr(result)
          } else {
            console.log('undefined')
          }
        }, 500)
        this.init('add')
      }

      // this.organizationId = this.item.id || undefined;
      // todo: remove logs
      console.log('edit-triggered', this.organizationId)
      console.log({ item })
      // console.log(this.isEditing);
      this.$nextTick(() => {
        let self = this
        console.log(self.$refs)
      })
    })

    EventBus.$on(OrganizationConfig.events.succeededStep1, (item) => {
      this.item = item
      // this.form.step1 = { ...this.item };
      var step1_init_data = this.getStepData(item, 1)
      this.$store.dispatch('organization/saveStepData', {
        step: 1,
        data: step1_init_data,
      })
      this.$store.dispatch('organization/nextStep', {
        step: 2,
      })
      this.$refs.stepper.nextWithBtn()
    })

    EventBus.$on(OrganizationConfig.events.succeededStep2, (item) => {
      var step2_init_data = this.getStepData(item, 2)
      this.$store.dispatch('organization/saveStepData', {
        step: 2,
        data: step2_init_data,
      })
      this.$store.dispatch('organization/nextStep', {
        step: 3,
      })
      console.log('aeo2', this.currentStep)
      console.log('aeo2', this.step2data)
      this.$refs.stepper.nextWithBtn()
      //this.$refs.stepper.next();
    })

    EventBus.$on(OrganizationConfig.events.succeededStep3, (item) => {
      var step3_init_data = this.getStepData(item, 3)
      this.$store.dispatch('organization/saveStepData', {
        step: 3,
        data: step3_init_data,
      })
      // this.$store.dispatch("organization/saveStepData", {
      //   step: 3,
      //   data: item
      // });
      this.$store.dispatch('organization/nextStep', {
        step: 4,
      })
      console.log('aeo3', this.currentStep)
      console.log('aeo3', this.step3data)
      this.$refs.stepper.nextWithBtn()
      //this.$refs.stepper.next();
    })

    EventBus.$on(OrganizationConfig.events.succeededStep4, (item) => {
      var step4_init_data = this.getStepData(item, 4)
      this.$store.dispatch('organization/saveStepData', {
        step: 4,
        data: step4_init_data,
      })

      localStorage.setItem('step1', '')
      localStorage.setItem('step2', '')
      localStorage.setItem('step3', '')
      localStorage.setItem('step4', '')
      // this.$store.dispatch("organization/saveStepData", {
      //   step: 4,
      //   data: item
      // });
      console.log('aeo4', this.currentStep)
      console.log('aeo4', this.step4data)
      // // todo: clear the store?
      this.$store.dispatch('organization/forgetStep')
      this.$store.dispatch('organization/forgetAllStepsData')
      // todo: close the sor & do other things
      dispatchEvent(new Event(OrganizationConfig.events.sorToggle))
      // todo: Refetch the indexData
      dispatchEvent(new Event(OrganizationConfig.events.refresh))
    })
  },
  methods: {
    changeData(data, step) {
      this.changeStep[step - 1] = data
    },
    checkExit() {
      var localStep1 = JSON.parse(localStorage.getItem('step1'))
      var localStep2 = JSON.parse(localStorage.getItem('step2'))
      var localStep3 = JSON.parse(localStorage.getItem('step3'))
      var localStep4 = JSON.parse(localStorage.getItem('step4'))
      if (
        localStep1 == '' &&
        localStep2 == '' &&
        localStep3 == '' &&
        localStep4 == ''
      ) {
        return true
      } else {
        if (this.changeStep.length > 0) {
          var isEqual = true
          for (var i = 0; i < this.changeStep.length; i++) {
            if (this.changeStep[i] == null) continue
            if (
              JSON.stringify(this.changeStep[i]) !==
                JSON.stringify(localStep1) &&
              i == 0
            ) {
              isEqual = false
              break
            } else if (
              JSON.stringify(this.changeStep[i]) !==
                JSON.stringify(localStep2) &&
              i == 1
            ) {
              isEqual = false
              break
            } else if (
              (JSON.stringify(this.changeStep[i].form) !==
                JSON.stringify(localStep3.form) ||
                JSON.stringify(this.changeStep[i].payment) !==
                  JSON.stringify(localStep3.payment)) &&
              i == 2
            ) {
              isEqual = false
              break
            } else if (i == 3) {
              var data = {
                full_name: localStep4.form.full_name,
                username: localStep4.form.username,
                phone_number: localStep4.form.phone_number,
                email: localStep4.form.email,
              }
              if (JSON.stringify(this.changeStep[i]) !== JSON.stringify(data)) {
                isEqual = false
                break
              }
            }
          }
          return isEqual
        } else {
          return true
        }
      }
    },
    showModal() {
      console.log('show = !!!!!!!!!!!!!!!!!! ')
      if (!this.checkExit()) {
        this.$emit('showmodal')
        return
      } else {
        dispatchEvent(new Event(OrganizationConfig.events.sorToggle))
        return
      }
    },
    init(type) {
      //console.log('indit = ', )
      if (type == 'add') {
        this.isFirst = false
        this.isLast = false
      } else {
        this.isFirst = false
        this.isLast = false
      }
    },
    showError(message) {
      if (message) {
        this.server_error_msg = message
        setTimeout(() => {
          this.server_error_msg = ''
        }, 3000)
      }
    },
    changeInitBottomBtn(index) {
      var next_count = 0
      var prev_count = 0
      for (var i = 0; i < this.stepStatusArr.length; i++) {
        if (
          this.stepStatusArr[i].visited &&
          !this.stepStatusArr[i].disabled &&
          i >= index &&
          i != this.stepStatusArr.length - 1
        ) {
          next_count += 1
        } else if (
          this.stepStatusArr[i].visited &&
          !this.stepStatusArr[i].disabled &&
          i < index
        ) {
          prev_count += 1
        }
      }
      if (next_count > 0) this.isLast = true
      else this.isLast = false

      if (prev_count > 0) this.isFirst = true
      else this.isFirst = false
    },
    goNextStep() {
      //this.changeInitBottomBtn(this.currentStep)
      this.$refs.stepper.next()
    },
    goPrevStep() {
      //this.changeInitBottomBtn(this.currentStep)
      this.$refs.stepper.previous()
    },
    //made by guang
    isValidFunc(flag, index, isNext, isPrevious) {
      //only move
      this.isValid = flag
      if (this.isValid || isPrevious) {
        this.$refs.stepper.enoughMove(index)
        this.isValid = false
      }
    },
    getStepsArr(data, index) {
      this.stepStatusArr = data
      this.changeInitBottomBtn(index)
    },
    checkErrors(oldIdx, index, isNext, isPrevious) {
      if (isPrevious && !isNext) {
        this.$refs.stepper.enoughMove(index)
      } else {
        this.changeInitBottomBtn(index)
        this.$refs[`step${oldIdx + 1}`].submit(
          'tabselect',
          index,
          isNext,
          isPrevious
        )
      }
    },
    changeTabs(index) {
      var vm = this
      setTimeout(() => {
        switch (index) {
          case 1:
            if (vm.$refs.step1) vm.$refs.step1.reloadData()
            break
          case 2:
            if (vm.$refs.step2) vm.$refs.step2.reloadData()
            break
          case 3:
            if (vm.$refs.step3) vm.$refs.step3.reloadData()
            break
          case 4:
            if (vm.$refs.step4) vm.$refs.step4.reloadData()
            break
        }
      }, 1000)
    },
    //

    submitStep: function(step) {
      this.$refs[`step${step}`].submit('normal')
    },

    getStepData: function(org, step) {
      var index = step - 1
      var result = {}
      switch (step) {
        case 1:
          result = {
            id: org.id,
            avatar: org.avatar,
            name: org.name,
            phone_number: org.phone_number,
            alias: org.alias,
            country: org.country.id,
            city: org.city.id,
            zip_code: org.zip_code,
            default_fleet: '',
            organization_category: org.organization_category,
            domain: org.domain,
            admin_domain: org.admin_domain,
            port: org.port,
            address: org.address,
          }
          if (org.id && org.name != undefined) {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: true,
              disabled: false,
            }
          } else {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: false,
              disabled: true,
            }
          }
          break
        case 2:
          if (org.subscription == null) {
            result = {
              subscriptionId: null,
              vehicleTypes: org.vehicle_type,
            }

            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: false,
              disabled: true,
            }
          } else {
            result = {
              subscriptionId: org.subscription.id,
              vehicleTypes: org.vehicle_type,
            }
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: true,
              disabled: false,
            }
          }
          break
        case 3:
          if (org.default_payment_gateway_config != null) {
            result = {
              payment: {
                id: org.default_payment_gateway_config.id,
                payment_gateway:
                  org.default_payment_gateway_config.payment_gateway,
                client_id: org.default_payment_gateway_config.client_id,
                secret_key: org.default_payment_gateway_config.secret_key,
                app_key: org.default_payment_gateway_config.app_key,
                extra_key: org.default_payment_gateway_config.id,
              },
              form: {
                play_store_link: org.play_store_link,
                apple_store_link: org.apple_store_link,
                website_link: org.website_link,
                email_address:
                  org.email_address == 'undefined' ? '' : org.email_address,
              },
            }
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: true,
              disabled: false,
            }
          } else {
            result = {
              payment: {},
              form: {
                play_store_link: org.play_store_link,
                apple_store_link: org.apple_store_link,
                website_link: org.website_link,
                email_address: org.email_address,
              },
            }
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: false,
              disabled: true,
            }
          }
          break
        case 4:
          if (org.owner_user == null) {
            result = {
              owner_user: {},
              form: {},
            }
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: false,
              disabled: true,
            }
          } else {
            result = {
              owner_user: {
                ...org.owner_user,
              },
              form: {
                full_name: org.owner_user.full_name,
                username: org.owner_user.username,
                phone_number: org.owner_user.phone_number,
                email: org.owner_user.email,
              },
            }
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: true,
              disabled: false,
            }
          }
          break
        default:
          break
      }
      return result
    },

    populateOrganizationStore: async function(organization) {
      // todo: clear store if id is undefined/empty
      await this.$http
        .get(OrganizationConfig.api.single(organization.id))
        .then((res) => {
          let org = res.data
          this.stepStatusArr = []
          var step1_init_data = this.getStepData(org, 1)
          var step2_init_data = this.getStepData(org, 2)
          var step3_init_data = this.getStepData(org, 3)
          var step4_init_data = this.getStepData(org, 4)

          localStorage.setItem('step1', JSON.stringify(step1_init_data))
          localStorage.setItem('step2', JSON.stringify(step2_init_data))
          localStorage.setItem('step3', JSON.stringify(step3_init_data))
          localStorage.setItem('step4', JSON.stringify(step4_init_data))

          this.changeInitBottomBtn(0)

          this.$store.dispatch('organization/saveEditingOrganizationId', {
            data: organization.id,
          })
          this.$store.dispatch('organization/saveEditingStep', {
            step: 1,
          })

          this.$store.dispatch('organization/saveStepData', {
            step: 1,
            data: step1_init_data,
          })
          this.$store.dispatch('organization/saveStepData', {
            step: 2,
            data: step2_init_data,
          })
          this.$store.dispatch('organization/saveStepData', {
            step: 3,
            data: step3_init_data,
          })
          this.$store.dispatch('organization/saveStepData', {
            step: 4,
            data: step4_init_data,
          })

          console.log('aftr-gt-popu-suc', this.$store.state.organization.step1)
          console.log(
            'aftr-gt-popu-suc-id',
            this.$store.state.organization.editingOrganizationId
          )
          if (this.$refs.stepper)
            this.$refs.stepper.changeStepsArr(this.stepStatusArr)
        })
        .catch((err) => {
          console.log('popu-err', err)
        })
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
