<template>
  <TModal
    ref="popup"
    :name="modalId"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="true"
    :clickToClose="true"
    :fixedClasses="{
      overlay: 'm-overlay',
      wrapper: 'm-wrapper',
      modal: 'm-modal',
    }"
    :classes="{
      body: 'm-body',
    }"
    @opened="fetchInvoices"
  >
    <div
      v-if="isLoading"
      class="py-1 px-5 w-full sm:py-3"
      style="max-height: 80vh; overflow:hidden"
    >
      <TheSuspense v-for="n in 3" :key="`s-${n}`" rounded>
        <SuspenseHeading img />
        <SuspenseText :lines="5" />
      </TheSuspense>
    </div>

    <template v-else>
      <header class="flex justify-between items-center shadow-md">
        <div>
          <h2 class="text-2xl font-medium text-oBlack">Make Payment</h2>
          <h2 class="text-sm text-oGray">
            Please select an invoice to make payment
          </h2>
        </div>

        <div class="text-right">
          <h2 class="text-2xl font-medium text-oRed">$0.00</h2>
          <h2 class="text-sm text-oGray">
            Total Due
          </h2>
        </div>
      </header>

      <main class="overflow-y-auto h-full bg-gray-100 sb-farhan">
        <div>
          <section>
            <div
              class="grid grid-cols-7 gap-1 py-2 px-1 text-sm font-medium bg-white rounded border border-gray-200 text-oDark"
            >
              <p class="pl-7">ID</p>
              <p>Issue Date</p>
              <p>Due Date</p>
              <p>Status</p>
              <p>Currency</p>
              <p>Amount</p>
              <p class="w-14">Invoice</p>
            </div>

            <template v-if="invoices.length < 1">
              <div
                class="py-2 px-3 mt-px bg-white rounded border border-gray-200"
              >
                <p class="text-center text-oDark">
                  No unpaid invoices found :)
                </p>
              </div>
            </template>
            <template v-else>
              <section
                v-for="invoice in invoices"
                :key="invoice.id"
                :class="
                  `rounded px-3 py-2 mt-px border transition duration-200 ease-in
                      ${
                        selectedInvoice === invoice.id
                          ? 'bg-blue-50 border-blue-500'
                          : 'bg-white border-gray-200'
                      }`
                "
              >
                <div
                  class="grid grid-cols-7 gap-1 items-center mt-2 text-sm rounded text-oDark"
                >
                  <div class="flex items-center">
                    <label
                      class="flex items-center text-blue-500 cursor-pointer"
                    >
                      <TRadioUnwrapped
                        variant="info"
                        :value="invoice.id"
                        v-model="selectedInvoice"
                      />
                      <span class="ml-2 text-sm">
                        {{ $truncate(invoice.id, -5) }}
                      </span>
                    </label>
                  </div>
                  <p>
                    {{ $localTime(invoice.issue_date) }}
                  </p>
                  <p class="text-oOrange">
                    {{ $localTime(invoice.due_date) }}
                  </p>
                  <XStatus
                    :text="invoice.status"
                    :profile="`payment`"
                    :variant="'orange'"
                  />
                  <p>{{ invoice.currency.name }}</p>
                  <p class="font-medium">
                    {{ invoice.currency.symbol + invoice.amount }}
                  </p>
                  <p>
                    <AppButton
                      height="30px"
                      width="30px"
                      :variant="
                        selectedInvoice === invoice.id ? 'light' : 'secondary'
                      "
                    >
                      <i class="fa fa-download" />
                    </AppButton>
                  </p>
                </div>
                <div
                  v-if="selectedInvoice === invoice.id"
                  class="flex gap-16 pt-4 mt-4 border-t border-blue-300"
                >
                  <div class="w-96">
                    <div class="bg-white rounded border border-gray-200">
                      <p class="p-3 text-sm border-b text-oBlack">
                        Selected card
                      </p>

                      <TDropdown variant="fullLeft">
                        <template
                          #trigger="{
                              mousedownHandler,
                              focusHandler,
                              blurHandler,
                              keydownHandler,
                            }"
                        >
                          <button
                            @blur="blurHandler"
                            @focus="focusHandler"
                            @keydown="keydownHandler"
                            @mousedown="mousedownHandler"
                            class="flex items-center px-2 h-16 text-left rounded border-b cursor-pointer justiy-between"
                          >
                            <div class="flex flex-col w-full">
                              <div>
                                <i
                                  class="text-xl"
                                  :class="
                                    getCreditCardIcon(getSelectedCard.brand)
                                  "
                                />
                                {{ getSelectedCard.number }}
                              </div>

                              <div class="text-xs text-left text-oLightGray">
                                {{ getSelectedCard.holder_name }}
                              </div>
                            </div>

                            <div class="w-3">
                              <i class="text-sm fa fa-chevron-down"></i>
                            </div>
                          </button>
                        </template>

                        <template #default="{ hide }">
                          <TDropdownItem
                            v-for="card in creditCards"
                            :key="card.id"
                            @click="
                              selectedCreditCard = card.id
                              hide()
                            "
                          >
                            <div class="flex flex-col w-full">
                              <div>
                                <i
                                  class="text-xl"
                                  :class="getCreditCardIcon(card.brand)"
                                />
                                {{ card.number }}
                              </div>

                              <div
                                class="ml-1 text-xs text-left text-oLightGray"
                              >
                                {{ card.holder_name }}
                              </div>
                            </div>
                          </TDropdownItem>
                        </template>
                      </TDropdown>

                      <div class="payment-failure-notice">
                        We were unable to charge your credit card for the amount
                        of $757.95 for your use of our Payment Gateway during
                        the month of 1 August, 2021 . We will attempt to collect
                        this amount again. Unless we are successful in
                        collecting the balance of $757.95 in full by 10 August,
                        2021, your IGNIT account will be Deactivated.
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-y-2 w-full">
                    <div
                      class="flex justify-between items-center p-5 bg-white rounded border shadow-sm"
                    >
                      <div class="flex gap-14 items-center">
                        <div>
                          <h2 class="font-medium text-oBlack">
                            Total Vehicles
                          </h2>
                          <h6 class="text-sm text-oLightGray">1-45</h6>
                        </div>
                        <div class="text-oLightGray">
                          x
                        </div>
                        <div>
                          <h2 class="font-medium text-oBlack">
                            Enterprise
                          </h2>
                          <h6 class="text-sm text-oGray">$8 / vehicle</h6>
                        </div>
                      </div>

                      <div class="flex gap-10 items-center w-32">
                        <div class="text-oLightGray">
                          =
                        </div>
                        <div>
                          <h2 class="font-medium text-oBlack">
                            Amount
                          </h2>
                          <h6 class="text-sm text-oLightGray">$360.00</h6>
                        </div>
                      </div>
                    </div>

                    <div
                      class="flex justify-between items-center p-5 bg-white rounded border shadow-sm"
                    >
                      <div class="flex gap-14 items-center">
                        <div>
                          <h2 class="font-medium text-oBlack">
                            Total Vehicles
                          </h2>
                          <h6 class="text-sm text-oLightGray">46-500</h6>
                        </div>
                        <div class="text-oLightGray">
                          x
                        </div>
                        <div>
                          <h2 class="font-medium text-oBlack">
                            Enterprise
                          </h2>
                          <h6 class="text-sm text-oGray">$5 / vehicle</h6>
                        </div>
                      </div>

                      <div class="flex gap-10 items-center w-32">
                        <div class="text-oLightGray">
                          =
                        </div>
                        <div>
                          <h2 class="font-medium text-oBlack">
                            Amount
                          </h2>
                          <h6 class="text-sm text-oLightGray">$2250.00</h6>
                        </div>
                      </div>
                    </div>

                    <div
                      class="flex flex-col gap-y-3 items-end p-5 bg-white rounded border shadow-sm text-oBlack"
                    >
                      <div class="flex gap-10 items-center">
                        <div class="w-40">
                          Subtotal
                        </div>
                        <div class="w-24 text-right">
                          $2610.00
                        </div>
                      </div>

                      <div class="flex gap-10 items-center">
                        <div class="w-40">
                          VAT
                        </div>
                        <div class="w-24 text-right">
                          $10.00
                        </div>
                      </div>

                      <div class="mt-2 border-b" style="width: 360px;"></div>

                      <div class="flex gap-10 items-center">
                        <div class="w-40">
                          Total
                        </div>
                        <div class="w-24 text-lg font-medium text-right">
                          $2620.00
                        </div>
                      </div>

                      <div class="flex gap-3 items-center mt-5">
                        <p class="text-xs text-gray-500">
                          3% bank charge will be added
                        </p>
                        <AppButton
                          variant="blue"
                          width="100px"
                          rounded="none"
                          text="Pay"
                          :is-loading="isProcessing"
                          @click="onPay"
                        />
                        <div class="pr-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </template>
          </section>
        </div>

        <!-- <template v-if="isTabActive('confirm-payment')">
          <div>
            <section class="flex justify-between">
              <div>
                <TDropdown variant="smActionsLeft">
                  <template
                    #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                      }"
                  >
                    <button
                      @blur="blurHandler"
                      @focus="focusHandler"
                      @keydown="keydownHandler"
                      @mousedown="mousedownHandler"
                      class="flex items-center px-2 w-40 h-16 bg-gray-100 rounded cursor-pointer justiy-between"
                    >
                      <div class="flex flex-col w-full">
                        <div>
                          <i
                            class="text-xl"
                            :class="getCreditCardIcon(getSelectedCard.brand)"
                          />
                          {{ getSelectedCard.number }}
                        </div>

                        <div class="ml-1 text-xs text-left text-oLightGray">
                          {{ getSelectedCard.holder_name }}
                        </div>
                      </div>

                      <div class="w-3">
                        <i class="text-sm fa fa-chevron-down"></i>
                      </div>
                    </button>
                  </template>

                  <template #default="{ hide }">
                    <TDropdownItem
                      v-for="card in creditCards"
                      :key="card.id"
                      @click="
                        selectedCreditCard = card.id
                        hide()
                      "
                    >
                      <div class="flex flex-col w-full">
                        <div>
                          <i
                            class="text-xl"
                            :class="getCreditCardIcon(card.brand)"
                          />
                          {{ card.number }}
                        </div>

                        <div class="ml-1 text-xs text-left text-oLightGray">
                          {{ card.holder_name }}
                        </div>
                      </div>
                    </TDropdownItem>
                  </template>
                </TDropdown>
              </div>

              <div class="flex gap-3 items-center">
                <div
                  class="flex flex-col justify-center pl-4 w-28 h-16 bg-gray-100 rounded"
                >
                  <div>140</div>
                  <div class="text-xs text-gray-500">Total Vehicles</div>
                </div>

                <div>x</div>

                <div
                  class="flex flex-col justify-center pl-4 w-28 h-16 bg-gray-100 rounded"
                >
                  <div>$10 / Vehicle</div>
                  <div class="text-xs text-gray-500">Enterprise</div>
                </div>

                <div>=</div>

                <div
                  class="flex flex-col justify-center pl-4 w-28 h-16 bg-gray-100 rounded"
                >
                  <div class="text-oGreen">$1400</div>
                  <div class="text-xs text-gray-500">Amount to pay</div>
                </div>
              </div>
            </section>

            <section>
              <div
                class="grid grid-cols-7 gap-1 py-2 px-1 mt-8 text-sm border-b text-oDark"
              >
                <p>ID</p>
                <p>Issue Date</p>
                <p>Due Date</p>
                <p>Status</p>
                <p>Currency</p>
                <p>Amount</p>
                <p>Invoice</p>
              </div>

              <div
                class="grid grid-cols-7 gap-1 py-2 text-sm  text-oDark mt-1.5 px-1 rounded items-center"
              >
                <p class="text-blue-500">{{ getSelectedInvoice.id }}</p>
                <p>{{ getSelectedInvoice.issue_date }}</p>
                <p class="text-oOrange">{{ getSelectedInvoice.due_date }}</p>
                <XStatus
                  :text="getSelectedInvoice.status"
                  :profile="`payment`"
                  :variant="'orange'"
                />
                <p>{{ getSelectedInvoice.currency }}</p>
                <p class="font-medium">{{ getSelectedInvoice.amount }}</p>
                <p>
                  <AppButton height="30px" width="30px" variant="secondary">
                    <i class="fa fa-download" />
                  </AppButton>
                </p>
              </div>
            </section>
          </div>
        </template> -->
      </main>
    </template>

    <!-- <footer class="flex justify-end p-4"> -->
    <!-- <AppButton :text="getActionButtonText" :disabled="!selectedInvoice" /> -->
    <!-- </footer> -->
  </TModal>
</template>

<script>
import { getCreditCardIcon } from '@/utils'

import XStatus from '@/components/badge/XStatus.vue'
import TDropdownItem from '@/components/dropdown/TDropdownItem.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'MakePaymentModal',
  components: { XStatus, TDropdownItem },

  props: {
    modalId: {
      type: [String, Number],
      default: 'MakePaymentModal',
    },
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      tabs: [
        {
          id: 'choose-invoice',
          name: 'Choose Invoice',
          icon: 'far fa-user',
        },
        {
          id: 'confirm-payment',
          name: 'Confirm Payment',
          icon: 'fas fa-credit-card',
        },
      ],
      selectedTab: 'choose-invoice',

      summary: {
        paid: 0,
        past_due: 0,
        total: 0,
        unpaid: 0,
      },

      invoices: [
        {
          id: 'NAkaOc1sE',
          name: 'Invoice 3',
          amount: '$0.00',
          currency: 'USD',
          issue_date: '01/01/2020',
          due_date: '01/01/2020',
          status: 'Unpaid',
          link: '',
        },
      ],
      selectedInvoice: 'AdkaIQ1sa',

      creditCards: [
        {
          id: 1,
          brand: 'Mastercard',
          holder_name: 'Riduanul Islam',
          number: '**** **** **** 5918',
          valid_through: '12/24',
          cvv: '***',
          country: 'United States',
        },
        {
          id: 2,
          brand: 'Visa',
          holder_name: 'Riduanul Islam',
          number: '**** **** **** 4800',
          valid_through: '12/21',
          cvv: '***',
          country: 'United States',
        },
      ],

      selectedCreditCard: '',

      isLoading: false,
      isProcessing: false,
    }
  },

  computed: {
    getSelectedTab() {
      return this.tabs.find((tab) => tab.id === this.selectedTab)
    },
    getSelectedInvoice() {
      return this.invoices.find(
        (invoice) => invoice.id === this.selectedInvoice
      )
    },
    hasSelectedInvoice() {
      return this.selectedInvoice !== ''
    },
    getActionButtonText() {
      return this.selectedTab === 'choose-invoice' ? 'Next' : 'Pay'
    },
    getSelectedCard() {
      if (!this.selectedCreditCard) return this.creditCards[0]

      return this.creditCards.find(
        (card) => card.id === this.selectedCreditCard
      )
    },
  },

  methods: {
    getCreditCardIcon,

    executeAction(type) {
      if (type === 'confrim' && this.isLoading === false) {
        this.$emit('confirm')
      }

      if (type === 'cancel' && this.isLoading === false) {
        this.$refs.popup.hide()
        this.$emit('cancel')
      }
    },

    onPay({ invoiceId, cardId } = {}) {
      this.selectedInvoice = invoiceId ?? this.selectedInvoice
      this.selectedCreditCard = cardId ?? this.selectedCreditCard

      if (!this.selectedInvoice) {
        this.$notify({
          group: 'bottomRight',
          type: 'error',
          title: 'Error',
          text: 'Please select an invoice',
        })
        return
      }

      const url = useEndpoints.organization.subscriptionInvoice.triggerPayment(
        this.selectedInvoice
      )
      const data = new FormData()
      this.selectedCreditCard &&
        data.append('payment_method_id', this.selectedCreditCard)

      this.isProcessing = true
      this.$http
        .post(url, data)
        .then(({ data }) => {
          this.$emit('payment:triggered', {
            invoiceId: this.selectedInvoice,
            cardId: this.selectedCreditCard,
          })
          // console.log(data)
          this.$notify({
            group: 'bottomRight',
            type: 'success',
            title: 'Trigger Payment',
            text:
              data?.detail ??
              'Payment processing has been triggered successfully',
          })
          this.$refs.popup.hide()
        })
        .catch((error) => {
          console.warn('onPayErr', error)
          this.$emit('payment:failed', {
            invoiceId: this.selectedInvoice,
            cardId: this.selectedCreditCard,
          })
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Trigger Payment',
            text: data?.detail ?? 'Payment processing could not be triggered!',
          })
        })
        .finally(() => (this.isProcessing = false))
    },

    fetchInvoices() {
      this.isLoading = true
      const url = useEndpoints.organization.subscriptionInvoice.index({
        paidOnly: false,
        unpaidOnly: true,
      })
      this.$http
        .get(url)
        .then(({ data }) => {
          // console.log(data)
          this.invoices = data.data
          this.selectedInvoice = this.invoices[0].id ?? ''
          this.summary = data.meta.summary
        })
        .catch((error) => console.warn('fetchInvoicesErr', error))
        .finally(() => (this.isLoading = false))
    },

    onChangeTab(tabId) {
      this.selectedTab = tabId
    },
    isTabActive(tabId) {
      return this.selectedTab === tabId
    },
  },
}
</script>

<style lang="scss">
$spacingX: 4rem;
$spacingY: 4rem;

.m-overlay {
  @apply z-250 inset-0 w-full h-full fixed bg-gray-500 bg-opacity-50 transition-all duration-100 ease-in-out;
}
.m-wrapper {
  @apply z-220 relative overflow-y-auto mx-auto transition-all duration-300 ease-in-out;

  margin: $spacingY $spacingX;
}
.m-modal {
  @apply bg-white overflow-hidden relative rounded shadow-lg;

  // height width here
  height: calc(100vh - (2 * #{$spacingY}));
}

.m-body {
  @apply text-sm text-gray-700;
  padding: 0 !important;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
  height: calc(100vh - (2 * #{$spacingY}));

  & header {
    grid-area: header;
  }

  & main {
    grid-area: main;
  }

  & footer {
    grid-area: footer;
  }

  & header,
  & footer,
  & main {
    @apply px-6 py-5;
  }
}

.x-container {
  @apply flex gap-4 py-5 px-7;

  .x-left {
    @apply w-28;
  }
  .x-right {
    @apply w-full;
  }
}

.x-tab-item {
  @apply pb-4 text-gray-600 border-b border-transparent cursor-pointer;
  @apply transition duration-200 ease-in-out;
  &:hover {
    @apply text-gray-700;
  }
  &.x-tab-active {
    @apply text-gray-700 border-b border-black font-bold;
  }
}

.x-notice {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 309px;
  height: 106px;

  background: #eefbff;
  border-radius: 4px;
  color: #115266;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem;

  &.green {
    color: #33693f;
    background: #ecfff0;
  }
}

.payment-failure-notice {
  @apply p-1.5 m-1 mt-2 text-xs  rounded;
  color: #b76e2c;
  background: #fff5ec;
}
</style>
