<template>
  <div class="min-h-screen">
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <loading :active.sync="isLoading" :is-full-page="false" />

      <form
        @submit.prevent="handleSubmit(submitStepTwo)"
        enctype="multipart/form-data"
        class="px-6"
      >
        <div class="flex items-center">
          <label
            class="circle"
            :class="getSelectedClass('SCOOTER')"
            v-tooltip.top="'Scooter'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="SCOOTER"
              v-model="vehicleTypes"
              @change="changeValues"
            />
            <img
              src="@/assets/icon/scooter.png"
              style="height: 26px; padding-bottom: 2px"
            />
          </label>

          <label
            class="circle"
            :class="getSelectedClass('BIKE')"
            v-tooltip.top="'Bike'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="BIKE"
              v-model="vehicleTypes"
              @change="changeValues"
            />
            <i class="fa fa-bicycle" style="font-size: 28px"></i>
          </label>

          <label
            class="circle"
            :class="getSelectedClass('EBIKE')"
            v-tooltip.top="'EBike'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="EBIKE"
              v-model="vehicleTypes"
              @change="changeValues"
            />
            <i class="fa fa-motorcycle" style="font-size: 28px"></i>
          </label>
        </div>

        <ValidationProvider
          name="Subscription plan"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label :error="errors[0]" text="Subscription Plan *" />
          <t-rich-select
            :options="subscriptionPlans"
            valueAttribute="id"
            textAttribute="plan_name"
            placeholder="Select a plan"
            :variant="{ danger: !!errors[0] }"
            v-model="subscriptionId"
            @change="changeValues"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>

        <button type="submit" ref="stepTwoSubmitButton" class="hidden">
          Save
        </button>
      </form>
    </ValidationObserver>

    <div class="px-6" v-if="subscriptionId">
      <div class="mb-3 text-sm font-bold text-gray-800 disable-border">
        Features of {{ subscription.plan_name }}
      </div>

      <div class="feature-item">
        <div class="left">Currency</div>
        <div class="right">
          {{ subscription.multi_currency_enabled ? 'Multiple' : 'Single' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Language</div>
        <div class="right">
          {{ subscription.multi_language_enabled ? 'Multiple' : 'Single' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Geofence Type</div>
        <div class="right">
          {{ subscription.multi_geofence_enabled ? 'Multiple' : 'Single' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Number of vehicles</div>
        <div class="right">
          {{ subscription.num_of_vehicles }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Number of fleets</div>
        <div class="right">
          {{ subscription.num_of_fleets }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Number of promo codes</div>
        <div class="right">
          {{ subscription.num_of_promo_codes }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Number of org user accounts</div>
        <div class="right">
          {{ subscription.num_of_org_user_accounts }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">End ride photo</div>
        <div class="right">
          {{ subscription.end_ride_photo_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">End ride photo</div>
        <div class="right">
          {{ subscription.end_ride_photo_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Access to operator app</div>
        <div class="right">
          {{ subscription.operator_app_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Point management</div>
        <div class="right">
          {{ subscription.point_management_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Pass management</div>
        <div class="right">
          {{ subscription.pass_management_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Ticket management</div>
        <div class="right">
          {{ subscription.ticket_management_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">Reporting</div>
        <div class="right">
          {{ subscription.reporting_enabled ? 'Yes' : 'No' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { OrganizationConfig } from '@/config/OrganizationConfig'
import { DropdownConfig } from '@/config/DropdownConfig'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

import InputLabel from '@/components/form/InputLabel'

export default {
  name: 'AddEditOrganizationStep2',
  components: {
    InputLabel,
  },
  data: function() {
    return {
      isLoading: false,
      // selected vehicle types
      vehicleTypes: [],
      // selected subscription plan
      subscription: undefined,
      subscriptionId: undefined,
      // all subscription plans detail
      subscriptionPlans: undefined,
    }
  },
  computed: {
    ...mapGetters('organization', [
      'isEditing',
      'currentEditingOrganizationId',
    ]),
    ...mapState({
      // retrieve organization detail from step 1
      step1: (state) => state.organization.step1,
      // retrieve subscription detail from step 2
      step2: (state) => state.organization.step2,
    }),
  },
  async created() {
    // todo: remove logs, push to errors array
    this.subscriptionPlans = await this.$http
      .get(DropdownConfig.api.subscription)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    if (this.isEditing) {
      // console.log(this.step2.subscriptionId);
      this.subscriptionId = this.step2.subscriptionId
      this.vehicleTypes = this.step2.vehicleTypes
    }
  },
  watch: {
    subscriptionId: function(updatedSubscriptionId) {
      this.subscription = this.subscriptionPlans.find((subscription) => {
        return subscription.id === updatedSubscriptionId
          ? subscription
          : undefined
      })
    },
  },
  methods: {
    changeValues() {
      console.log('data = ')
      var data = {
        subscriptionId: this.subscriptionId,
        vehicleTypes: this.vehicleTypes,
      }
      this.$emit('changedata', data, 2)
    },
    reloadData: function() {
      if (this.step2.subscriptionId == null) this.subscriptionId = null
      else this.subscriptionId = this.step2.subscriptionId

      this.vehicleTypes = this.step2.vehicleTypes
    },
    submit: async function(btn_type, index, isNext, isPrevious) {
      if (btn_type != 'normal') {
        const isValid = await this.$refs.observer.validate()
        this.$emit('isValid', isValid, index, isNext, isPrevious)
      } else {
        this.$refs.stepTwoSubmitButton.click()
      }
    },
    submitStepTwo: async function() {
      this.isLoading = true

      let method = 'PATCH'
      let url = OrganizationConfig.api.update(this.step1.id)
      let data = new xMan({
        subscription: this.subscriptionId,
        vehicle_type: JSON.stringify(this.vehicleTypes),
      }).toFormData()
      let message = 'Step 2 completed successfully'

      // todo: remove logs
      console.log('step2 started submitting')
      console.log({ step2: data })
      console.log({ step2: url })

      try {
        let response = await this.$http({ method, url, data })
        console.log(typeof response)
        console.log({ res: response.data })
        this.isLoading = false
        this.form = {}
        this.$notify(
          {
            group: 'important',
            type: 'success',
            title: 'Success',
            text: message,
          },
          5000
        )

        // Resolved
        EventBus.$emit(OrganizationConfig.events.succeededStep2, response.data)
        return true
      } catch (e) {
        // todo: remove logs
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        // console.log({ sdhf: typeof error });
        // console.log({ e1: Object.keys(error) });
        console.log({ e2: e.response.data })
        // for (let i = 0; i < e.response.data.length; i++) {
        //   this.errors.push(e.response.data[i]);
        // }
        // Rejected
        // throw new Error(error.status);
      }
    },
    getSelectedClass: function(val) {
      return {
        selected: this.vehicleTypes.includes(val),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$yellow: #fff310;
$gray: #9c9c9c;
$light-gray: #e2e8f0;

.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  padding: 0; // 15px 0?

  & .left {
    width: 55%;
    font-size: 0.875rem;
    padding: 0.375rem 0;
    // padding-right: 0.5rem;
    font-weight: 500;
  }
  & .right {
    width: 45%;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.375rem 0;
    // padding-left: 0.5rem;
    color: $gray;
  }
}

.circle {
  width: 55px;
  height: 55px;
  margin: 17px 11px;
  display: flex;
  justify-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 13px 15px;
  border: 1px solid $gray;
  // background-color: $gray;

  &:hover {
    background-color: lighten($yellow, 18%);
  }

  &.selected {
    background-color: $yellow;
  }
}
</style>
