<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />
    <form
      @submit.prevent="handleSubmit(submitStepOne)"
      enctype="multipart/form-data"
      class="sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1 "
    >
      <upload-avatar
        v-model="form.avatar"
        :current="form.avatar"
        class="my-3"
      />

      <div class="flex items-center">
        <!-- start: name & phone -->
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Name"
            placeholder="Organization name"
            v-model="form.name"
            v-on:input="changeValues"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules="required"
            label="Phone number"
            placeholder="Phone number"
            v-model="form.phone_number"
            input_type="phone"
            v-on:input="changeValues"
          />
        </div>
      </div>
      <!-- end: name & phone -->
      <!-- start: alias & country -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            placeholder="Alias name"
            rules="required"
            label="Alias"
            v-model="form.alias"
            v-on:input="changeValues"
          />
        </div>
        <div class="w-1/2 pl-2">
          <ValidationProvider
            name="Country"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Country *" />
            <t-rich-select
              :options="countries"
              valueAttribute="id"
              textAttribute="name"
              placeholder="Select a country"
              :variant="{ danger: !!errors[0] }"
              v-model="form.country"
              v-on:input="changeValues"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </div>
      <!-- end: alias & country -->

      <!-- start: city & zip -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <ValidationProvider name="City" rules="required" v-slot="{ errors }">
            <input-label :error="errors[0]" text="City *" />
            <t-rich-select
              :options.sync="cities"
              valueAttribute="id"
              textAttribute="name"
              :placeholder.sync="getCityPlaceholder"
              :disabled="getCityDisabledState"
              :variant="{ danger: !!errors[0] }"
              v-model="form.city"
              v-on:input="changeValues"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            placeholder="Zip code"
            rules="required"
            label="Zip Code"
            v-model="form.zip_code"
            v-on:input="changeValues"
          />
        </div>
      </div>
      <!-- end: city & zip -->

      <!-- start: Default-fleet & Type -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <ValidationProvider
            name="Default fleet"
            :rules="getDefaultFleetValidationRules"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Default Fleet" />
            <t-rich-select
              :placeholder="getDefaultFleetPlaceholder"
              :options="getDefaultFleetOptions"
              valueAttribute="id"
              textAttribute="name"
              :variant="{ danger: !!errors[0] }"
              tabindex="0"
              v-model="form.default_fleet"
              v-on:input="changeValues"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
        <div class="w-1/2 pl-2">
          <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
            <input-label :error="errors[0]" text="Type *" />
            <t-rich-select
              placeholder="Organization Type"
              :options="getOrganizationTypeOptions"
              valueAttribute="id"
              textAttribute="name"
              :hideSearchBox="true"
              :variant="{ danger: !!errors[0] }"
              v-model="form.organization_category"
              v-on:input="changeValues"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </div>
      <!-- end: Default-fleet & type -->

      <!-- start: Domain & Port -->
      <!-- <div class="flex">
        <div class="w-1/2 pr-2">
          <text-input
            placeholder="8080"
            rules="required"
            label="Port"
            v-model="form.port"
            v-on:input="changeValues"
          />
        </div>
        <div class="w-1/2 pl-2">
          <ValidationProvider
            name="Address"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Address *" />
            <t-textarea
              rules="required"
              v-model="form.address"
              v-on:input="changeValues"
            ></t-textarea>
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </div> -->
      <!-- end: Domain & Port -->

      <div class="w-full">
        <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
          <input-label :error="errors[0]" text="Address *" />
          <t-textarea
            rules="required"
            v-model="form.address"
            v-on:input="changeValues"
          ></t-textarea>
          <input-error-item :message="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="domain-title">
        <span>Domain</span>
      </div>
      <!-- start: address -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            placeholder="www.example.com"
            rules="required"
            label="Admin Domain"
            v-model="form.admin_domain"
            v-on:input="changeValues"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            placeholder="www.example.com"
            rules="required"
            label="App Domain"
            v-model="form.domain"
            v-on:input="changeValues"
          />
        </div>
      </div>
      <!-- end: adress -->
      <button type="submit" ref="stepOneSubmitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { OrganizationConfig } from '@/config/OrganizationConfig'
import { DropdownConfig } from '@/config/DropdownConfig'

import { mapGetters } from 'vuex'

import UploadAvatar from '@/components/form/UploadAvatar'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'

export default {
  name: 'AddEditOrganizationStep1',
  components: {
    UploadAvatar,
    InputLabel,
    TextInput,
  },
  props: {
    profile_data: {
      required: false,
    },
  },
  data: function() {
    return {
      isLoading: false,
      item: {},
      form: {},
      // country & city
      countries: undefined,
      cities: undefined,
      cityIsLoading: false,
      country_code: 1,
    }
  },
  computed: {
    ...mapGetters('organization', [
      'isEditing',
      'currentEditingOrganizationId',
    ]),
    step1: function() {
      return this.$store.state.organization.step1
    },
    getCountry: function() {
      return this.form.country || undefined
    },
    getCityDisabledState: function() {
      if (!this.form.country) return true
      if (this.cityIsLoading) return true
      return false
    },
    getCityPlaceholder: function() {
      if (!this.form.country) return 'Select a country first'
      if (this.cityIsLoading) return 'Loading ⟳'
      return 'Select city'
    },
    getDefaultFleetPlaceholder: function() {
      if (this.isEditing) return '--'
      return 'Select default fleet'
    },
    getDefaultFleetOptions: function() {
      // if (this.isEditing) {
      //   // todo: fetch fleets in editng mode
      //   return [
      //     {
      //       id: 1,
      //       name: "Test"
      //     },
      //     {
      //       id: 2,
      //       name: "Nest"
      //     }
      //   ];
      // }
      return [
        {
          id: '',
          name: '--',
        },
      ]
    },
    getDefaultFleetValidationRules: function() {
      // todo: re-enable default-fleet validation in editing mode after getting data in proper format
      // if (this.isEditing) return "required";
      return ''
    },
    getOrganizationTypeOptions: function() {
      return [
        {
          id: 'TESTING',
          name: 'Testing',
        },
        {
          id: 'LIVE',
          name: 'Live',
        },
      ]
    },
  },
  async created() {
    this.form.default_fleet = '' //init

    // todo: remove logs, push to errors array
    this.countries = await this.$http
      .get(DropdownConfig.api.country)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },
  // mounted() {
  //   EventBus.$on(OrganizationConfig.events.editingStep1Data, item => {
  //     // todo: remove logs
  //     console.log({ step1: item });
  //     console.log({ step1: this.isEditing });

  //     this.item = item;
  //     this.form = { ...this.item };
  //   });
  // },
  // beforeDestroy() {
  //   EventBus.$off(OrganizationConfig.events.editingStep1Data);
  //   EventBus.$off(OrganizationConfig.events.succeededStep1);
  // },
  watch: {
    getCountry: async function(updatedCountry) {
      this.form.city = this.isEditing ? this.form.city : this.form.city
      this.cityIsLoading = true

      this.cities = await this.$http
        .get(DropdownConfig.api.city(updatedCountry))
        .then((res) => res.data.data)
        .catch((err) => console.log(err))
        .finally(() => (this.cityIsLoading = false))
    },
    currentEditingOrganizationId: function(updatedId) {
      console.log(this.currentEditingOrganizationId)
      console.log('wae1', this.step1)
      if (updatedId !== undefined) {
        this.form = {
          ...this.step1,
        }
        return
      }
      this.form = {}
      return
    },
  },
  methods: {
    changeValues() {
      this.$emit('changedata', this.form, 1)
    },
    reloadData: function() {
      this.form = this.step1
    },
    saveCountryCode(countrycode) {
      this.country_code = countrycode
    },
    submit: async function(btn_type, index, isNext, isPrevious) {
      if (btn_type != 'normal') {
        const isValid = await this.$refs.observer.validate()
        this.$emit('isValid', isValid, index, isNext, isPrevious)
      } else {
        this.$refs.stepOneSubmitButton.click()
      }
    },
    submitStepOne: async function() {
      this.isLoading = true

      console.log('s1-sub', this.isEditing)
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? OrganizationConfig.api.update(this.currentEditingOrganizationId)
        : OrganizationConfig.api.create
      if (typeof this.form.avatar === 'string' || this.form.avatar == null) {
        delete this.form.avatar
      }

      if (typeof this.form.port === 'undefined' || this.form.avatar == null) {
        this.form.port = '8080'
      }

      let data = new xMan(this.form).toFormData()
      console.log('form = ', this.form, url)
      let message = this.isEditing
        ? 'Organization updated successfully'
        : 'Organization added successfully'

      // todo: remove logs
      console.log('step1 started submitting')
      // console.log({ step1: this.isEditing });
      // console.log({ step1: this.form });

      // console.log({ step1: data });
      // console.log({ step1: url });

      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // todo: remove logs
        console.log({ res: response.data })
        // Reset the form
        this.form = {}
        // Notify
        this.$notify(
          {
            group: 'important',
            type: 'success',
            title: 'Success',
            text: message,
          },
          5000
        )
        // Resolved
        console.log('response = ', response.data)
        EventBus.$emit(OrganizationConfig.events.succeededStep1, response.data)
        return true
      } catch (e) {
        // todo: remove logs
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        // console.log({ sdhf: typeof error });
        // console.log({ e1: Object.keys(error) });
        console.log({ e1: e.response.data })
        //console.log()
        var errorData = Object.values(e.response.data)
        if (errorData.length > 0) {
          this.$emit('show_error', errorData[0][0])
        }
        // for (let i = 0; i < e.response.data.length; i++) {
        //   this.errors.push(e.response.data[i]);
        // }
        // Rejected
        // throw new Error(error.status);
      }
    },
  },
}
</script>
