<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />
    <form
      @submit.prevent="handleSubmit(submitStepThree)"
      enctype="multipart/form-data"
      class="px-6"
    >
      <ValidationProvider
        name="Payment gateway"
        rules="required"
        v-slot="{ errors }"
      >
        <input-label :error="errors[0]" text="Payment gateway *" />
        <t-rich-select
          :options="gateways"
          valueAttribute="id"
          textAttribute="name"
          placeholder="Select a gateway"
          :hideSearchBox="true"
          :variant="{ danger: !!errors[0] }"
          v-model="payment.payment_gateway"
          @change="changeValues"
        />
        <input-error-item :message="errors[0]" />
      </ValidationProvider>

      <!-- start: client-id & secret-key -->
      <!-- <div class="flex items-center"> -->
      <!-- <div class="w-1/2 pr-2"> -->
      <div class="w-full">
        <text-input
          rules="required"
          type="password"
          label="Client ID"
          placeholder="**************"
          v-model="payment.client_id"
          v-on:input="changeValues"
        />
      </div>
      <!-- <div class="w-1/2 pl-2"> -->
      <div class="w-full">
        <text-input
          rules="required|min:6"
          type="password"
          label="Secret key"
          placeholder="**************"
          v-model="payment.secret_key"
          v-on:input="changeValues"
        />
      </div>
      <!-- </div> -->
      <!-- end: client-id & secret-key -->

      <!-- start: app-key & extra-key -->
      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            type="password"
            label="App Key"
            placeholder="**************"
            v-model="payment.app_key"
            v-on:input="changeValues"
          />
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules=""
            label="Extra Key"
            type="password"
            placeholder="**************"
            v-model="payment.extra_key"
            v-on:input="changeValues"
          />
        </div>
      </div>
      <!-- end: private-key & default-currency -->
      <div class="my-5 text-sm font-bold text-gray-500">
        Domain
      </div>
      <div v-for="(domain, index) in vehicleDomains" :key="index">
        <div class="grid grid-cols-3 gap-3">
          <div class="">
            <text-input
              rules=""
              label="Vehicle Type"
              placeholder="Write a domain"
              :value="getVehicleNameByFlag(domain.vehicle_category)"
              variant="readonly"
              :disabled="true"
            />
          </div>

          <div class="">
            <text-input
              rules="required"
              label="Domain"
              placeholder="e.g dev-iot.otoride.co"
              v-model="domain.domain"
            />
          </div>

          <div class="">
            <text-input
              rules="required"
              label="Port"
              :placeholder="getPortByFlag(domain.vehicle_category)"
              v-model="domain.port"
              :disabled="false"
            />
          </div>
        </div>
      </div>

      <!-- <div class="flex items-center my-5 text-sm font-bold text-gray-500">
        <span>Vehicles</span>
        <div
          class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
          @click="addVehicles"
        >
          <i class="fas fa-plus-circle" style="color:black;"></i>
        </div>
      </div> -->

      <!-- <div
        class="flex items-center"
        v-for="(item, index) in vehicle_types"
        :key="index"
      >
        <div class="flex justify-center w-1/12 item-center">
          <div
            class="col-span-1 cursor-pointer focus:text-gray-400"
            @click="removeVehicle(index)"
          >
            <i class="fas fa-minus-circle" style="color:#d90a20;"></i>
          </div>
        </div>
        <div class="flex items-center w-11/12">
          <div class="w-4/12 ml-2">
            <ValidationProvider
              name="Vehicle Types"
              rules=""
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Vehicle Type" />
              <t-rich-select
                :options="bike_categories"
                valueAttribute="id"
                textAttribute="name"
                placeholder="Select Type"
                :hideSearchBox="true"
                :variant="{ danger: !!errors[0] }"
                v-model="v_type"
                @change="onChangeVehicle(index)"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>
          <div class="w-4/12 ml-2">
            <text-input
              rules=""
              label="Domain"
              placeholder="Write a domain"
              v-model="v_domain"
              v-on:input="onChangeVehicle(index)"
            />
          </div>
          <div class="w-4/12 ml-2">
            <text-input
              rules=""
              label="Port"
              placeholder="Write a port"
              v-model="v_port"
              v-on:input="onChangeVehicle(index)"
            />
          </div>
        </div>
      </div> -->

      <!-- start: web-links -->
      <div class="my-5 text-sm font-bold text-gray-500">
        Web Links
      </div>

      <div class="flex items-center">
        <div class="w-full">
          <text-input
            rules=""
            label="Play Store"
            placeholder="https://play.google.com/store/apps"
            v-model="form.play_store_link"
            v-on:input="changeValues"
          />
        </div>
        <div class="web-link-icon">
          <i class="fab fa-google-play"></i>
        </div>
      </div>

      <div class="flex items-center">
        <div class="w-full">
          <text-input
            rules=""
            label="Apple Store"
            placeholder="https://apple.com/ios/store/apps"
            v-model="form.apple_store_link"
            v-on:input="changeValues"
          />
        </div>
        <div class="web-link-icon">
          <i class="fab fa-apple"></i>
        </div>
      </div>

      <div class="flex items-center">
        <div class="w-full">
          <text-input
            rules=""
            label="Website"
            placeholder="https://example.com"
            v-model="form.website_link"
            v-on:input="changeValues"
          />
        </div>
        <div class="web-link-icon">
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
      </div>

      <div class="flex items-center">
        <div class="w-full">
          <text-input
            rules="email"
            label="Email"
            placeholder="you@example.com"
            v-model="form.email_address"
            v-on:input="changeValues"
          />
        </div>
        <div class="web-link-icon">
          <i class="fas fa-envelope"></i>
        </div>
      </div>

      <!-- end: web-links -->

      <button type="submit" ref="stepThreeSubmitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { DropdownConfig } from '@/config/DropdownConfig'
import { OrganizationConfig } from '@/config/OrganizationConfig'
import { PaymentGatewayConfig } from '@/config/PaymentGatewayConfig'

import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

import InputLabel from '@/components/form/InputLabel'
import TextInput from '@/components/form/TextInput'

export default {
  name: 'AddEditOrganizationStep2',
  components: {
    InputLabel,
    TextInput,
  },
  data: function() {
    return {
      isLoading: false,
      // All gateways
      gateways: [],
      // Payment gateway
      payment: {},
      // Web links
      form: {},
      vehicle_types: [],
      vehicleDomains: [],
      bike_categories: [
        {
          id: 0,
          name: 'Scooter',
          value: 'S',
        },
        {
          id: 1,
          name: 'Bike',
          value: 'P',
        },
        {
          id: 2,
          name: 'EBike',
          value: 'E',
        },
      ],
      v_type: '',
      v_domain: '',
      v_port: '',
    }
  },
  computed: {
    ...mapGetters('organization', [
      'isEditing',
      'currentEditingOrganizationId',
    ]),
    isPaymentConfigCreated() {
      //  todo: need a flag if it has been created
      return this.payment && this.payment.id
    },
    ...mapState({
      // retrieve organization detail from step 1
      step1: (state) => state.organization.step1,
      // retrieve subscription detail from step 2
      step2: (state) => state.organization.step2,
      // retrieve payment & web-links detail from step 3
      step3: (state) => state.organization.step3,
    }),
  },
  watch: {
    // isEditing: function(newVal, oldVal) {
    //   // if (this.isEditing) {
    //   //   this.payment = { ...this.step3.payment };
    //   //   this.form = { ...this.step3.form };
    //   // }
    // }
  },
  async created() {
    // todo: remove logs, push to errors array
    console.log('api = ', DropdownConfig.api.paymentGateway)
    this.gateways = await this.$http
      .get(DropdownConfig.api.paymentGateway)
      .then((res) => res.data.data)
      .catch((err) => console.log('s3', err))

    await this.$http
      .get(
        OrganizationConfig.api.vehicleDomain.index(
          this.currentEditingOrganizationId
        )
      )
      .then((res) => {
        console.log('s2VehicleDomain', res.data.data)
        this.vehicleDomains = res.data.data
      })
      .catch((err) =>
        console.warn(
          's3VehicleDomainErr',
          err,
          this.currentEditingOrganizationId
        )
      )

    // todo: add watcher
    if (this.isEditing) {
      this.payment = this.step3.payment
      this.form = this.step3.form
    }
    console.log('step3 = ', this.step3)
    console.log({ moun3: this.payment })

    // alert(this.payment.payment_gateway);
    // alert(this.payment.id);
    // console.log(this.payment);
  },
  methods: {
    getVehicleNameByFlag(flag) {
      let aliases = {
        S: 'Bike',
        P: 'Scooter',
        E: 'EBike',
      }
      return flag in aliases ? aliases[flag] : '--'
    },
    getPortByFlag(flag) {
      let aliases = {
        S: '8192',
        P: '8193',
        E: '8194',
      }
      return flag in aliases ? aliases[flag] : '--'
    },
    removeVehicle(index) {
      this.vehicle_types.splice(index, 1)
    },
    addVehicles() {
      var obj = {
        v_type: '',
        v_domain: '',
        v_port: '',
      }
      this.vehicle_types.push(obj)
    },
    onChangeVehicle(index) {
      console.log('index = ', index)
      console.log('data = ', this.v_type, this.v_domain, this.v_port)
    },
    changeValues() {
      var url_link = {
        play_store_link: this.form.play_store_link,
        apple_store_link: this.form.apple_store_link,
        website_link: this.form.website_link,
        email_address: this.form.email_address,
      }
      var data = {
        form: url_link,
        payment: this.payment,
      }
      console.log('step3-1 = ', this.step3)
      this.$emit('changedata', data, 3)
    },
    reloadData: function() {
      this.form = this.step3.form
      this.payment = this.step3.payment
    },
    submit: async function(btn_type, index, isNext, isPrevious) {
      // // todo: remove fake completion
      // EventBus.$emit(OrganizationConfig.events.succeededStep3, {
      //   step3: "step3"
      // });
      // this.$refs.stepThreeSubmitButton.click();

      // const isValid = await this.$refs.observer.validate()
      // this.$emit("isValid", isValid)

      if (btn_type != 'normal') {
        const isValid = await this.$refs.observer.validate()
        this.$emit('isValid', isValid, index, isNext, isPrevious)
      } else {
        this.$refs.stepThreeSubmitButton.click()
      }
    },
    submitStepThree: async function() {
      // let url = "";
      let gatewayUrl = ''
      let gatewayMethod = ''

      let vehicleUrl = ''
      let vehicleMethod = ''

      // alert(this.payment.payment_gateway);
      // alert(this.payment.id);
      // console.log(this.payment);
      if (!this.isEditing && this.step3 == null) {
        // adding
        gatewayUrl = PaymentGatewayConfig.api.create
        gatewayMethod = 'POST'

        // note: vehicleDomains are always created in step 2
        // vehicleUrl = OrganizationConfig.api.vehicleDomain.create(
        //   this.currentEditingOrganizationId
        // );
        // vehicleMethod = "POST";
      } else {
        // editing
        if (this.isPaymentConfigCreated) {
          gatewayUrl = gatewayUrl = PaymentGatewayConfig.api.update(
            this.step3.payment.id
          )
          gatewayMethod = 'PATCH'
          console.log('patch,config', this.payment.id)
        } else {
          // todo: what if user has completed step 3?
          // in draft mode -> the payment gateway might not been created
          gatewayUrl = PaymentGatewayConfig.api.create
          gatewayMethod = 'POST'
        }

        // editingMode vehicleUrl has to be generetad upon sending req
        // as it'll be inside for loop & it needs the id
      }

      this.payment.organization = this.step1.id
      let gatewayData = new xMan(this.payment).toFormData()

      let message = 'Step 3 completed successfully'

      // // todo: remove logs
      // console.log("step3 started submitting");
      // console.log({ step2: data });
      // console.log({ step2: url });

      this.isLoading = true
      // payment gateway
      await this.$http({
        method: gatewayMethod,
        url: gatewayUrl,
        data: gatewayData,
      })
        .then((res) => {
          console.log('s3-1', res.data)

          // web-links
          let linksMethod = 'PATCH'
          let linksUrl = OrganizationConfig.api.update(this.step1.id)
          let linksData = new xMan(this.form).toFormData()

          this.$http({ method: linksMethod, url: linksUrl, data: linksData })
            .then((linksRes) => {
              // Resolved
              // todo: reset the form?
              // this.form = {};
              // this.payment = {};
              // todo: remove logs
              console.log(linksRes)
              this.$notify(
                {
                  group: 'important',
                  type: 'success',
                  title: 'Success',
                  text: message,
                },
                3000
              )
              console.log(this.payment, this.form)
              EventBus.$emit(
                OrganizationConfig.events.succeededStep3,
                linksRes.data
              )
            })
            .catch((err) => {
              // todo: show/handle server errors
              console.log('s3-2', err)
              alert('s3-2 server err')
            })
        })
        .catch((err) => {
          // todo: show/handle server errors
          console.log('s3-1:', err)
          console.log(err)
          alert('s3-1: server error')
          // // todo: remove logs
          // console.log({ sdhf: typeof error });
          // console.log({ e1: Object.keys(error) });
        })
        .finally(() => {
          this.isLoading = false
        })

      if (this.vehicleDomains.length > 0) {
        this.vehicleDomains.forEach(async (val) => {
          let vehicleUrl = OrganizationConfig.api.vehicleDomain.update(val.id)

          delete val.id

          let formData = new xMan(val).toFormData()
          await this.$http({
            url: vehicleUrl,
            method: 'PATCH',
            data: formData,
          })
            .then((res) => {
              console.log('vehDom', res, val)
            })
            .catch((err) => {
              console.log('vehDom', err, val)
            })
        })
      }
      if (Object.keys(this.vehicles.bike).length > 2) {
        // this.vehicles.bike.vehicle_category = "P";
        let bike = new xMan(this.vehicles.bike).toFormData()
        await this.$http({
          url: vehicleUrl,
          method: vehicleMethod,
          data: bike,
        })
          .then((res) => {
            console.log('vike', res)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.web-link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 42px;
  background: rgba(243, 244, 246, 1);
  border: 1px solid rgba(203, 213, 224, 1);
  margin-left: 0.25rem;
  border-radius: 0.25rem;
}
.minus-btn {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #d90a20;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
