<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />
    <form
      @submit.prevent="handleSubmit(submitStepFour)"
      enctype="multipart/form-data"
      class="px-6"
    >
      <!-- start: full-name & username -->
      <div class="flex items-center">
        <div class="pr-2 w-1/2">
          <text-input
            rules="required"
            label="Full name"
            placeholder="John doe"
            v-model="form.full_name"
            v-on:input="changeValues"
          />
        </div>
        <div class="pl-2 w-1/2">
          <text-input
            rules="required|min:4"
            label="Username"
            placeholder="john_doe"
            v-model="form.username"
            v-on:input="changeValues"
          />
        </div>
      </div>
      <!-- end: full-name & username -->

      <!-- start: phone-number & email -->
      <!-- required|regex:/^[2-9]\d{2}[2-9]\d{2}\d{4}$/ -->
      <div class="flex items-center">
        <div class="pr-2 w-1/2">
          <text-input
            rules="required"
            label="Phone number"
            placeholder="Phone number"
            v-model="form.phone_number"
            input_type="phone"
            v-on:input="changeValues"
          />
        </div>
        <div class="pl-2 w-1/2">
          <text-input
            rules="required|email"
            label="Email"
            placeholder="john_doe@gmail.com"
            v-model="form.email"
            v-on:input="changeValues"
          />
        </div>
      </div>
      <!-- end: phone-number & email -->

      <!-- start: password & password-confirmation -->
      <ValidationObserver>
        <div class="flex items-center">
          <div class="pr-2 w-1/2">
            <text-input
              :rules="getPasswordValidationRules"
              name="password"
              vid="password"
              type="password"
              label="Password"
              placeholder="*** *** ***"
              v-model="password"
            />
          </div>
          <div class="pl-2 w-1/2">
            <text-input
              rules="confirmed:password"
              type="password"
              name="confirm"
              vid="confirm"
              label="Confirm Password"
              placeholder="*** *** ***"
              v-model="passwordConfirmation"
            />
          </div>
        </div>
      </ValidationObserver>
      <!-- end: password & password-confirmation -->

      <button type="submit" ref="stepFourSubmitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import xMan from '@/utils/xMan'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { OrganizationConfig } from '@/config/OrganizationConfig'
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'

import TextInput from '@/components/form/TextInput'

export default {
  name: 'AddEditOrganizationStep4',
  components: {
    TextInput,
  },
  data: function() {
    return {
      isLoading: false,
      form: {},
      password: undefined,
      passwordConfirmation: undefined,
    }
  },
  mounted() {
    //console.log("c4", this.step4.owner_user.id);
    if (this.isEditing) {
      this.form = { ...this.step4.form }
    }
  },
  computed: {
    ...mapGetters('organization', [
      'isEditing',
      'currentEditingOrganizationId',
    ]),
    ...mapState({
      // retrieve organization detail from step 1
      step1: (state) => state.organization.step1,
      // retrieve subscription detail from step 2
      step2: (state) => state.organization.step2,
      // retrieve payment & web-links detail from step 3
      step3: (state) => state.organization.step3,
      // retrieve organization owner detail from step 4
      step4: (state) => state.organization.step4,
    }),
    getPasswordValidationRules() {
      return this.isEditing ? 'min:8' : 'required|min:8'
    },
  },
  methods: {
    changeValues() {
      this.$emit('changedata', this.form, 4)
    },
    reloadData: function() {
      this.form = this.step4.form
    },
    submit: async function(btn_type, index, isNext, isPrevious) {
      if (btn_type != 'normal') {
        const isValid = await this.$refs.observer.validate()
        this.$emit('isValid', isValid, index, isNext, isPrevious)
      } else {
        this.$refs.stepFourSubmitButton.click()
      }
    },
    submitStepFour: async function() {
      this.isLoading = true

      // handle draft mode as well (on draft mode, there's no org id)
      const orgId = this.step4.owner_user.id
      let method =
        this.isEditing && typeof orgId === 'string' ? 'PATCH' : 'POST'

      let url = this.isEditing
        ? OrganizationUserConfig.api.update(orgId)
        : OrganizationUserConfig.api.create

      if (this.isEditing) {
        this.form.org_edit_mode = ''
      } else {
        this.form.org_create_mode = ''
      }
      if (this.password === this.passwordConfirmation) {
        this.form.password = this.password
      }
      this.form.organization = this.step1.id
      let data = new xMan(this.form).toFormData()

      let message = 'Step 4 completed successfully'

      // todo: remove logs
      console.log('step4 started submitting')
      console.log({ step4: data })
      console.log({ step4: url })
      await this.$http({ method, url, data })
        .then((res) => {
          // Resolved
          this.isLoading = false
          console.log({ res4: res.data })
          // todo: reset form?
          // this.form = {};
          this.$notify(
            {
              group: 'important',
              type: 'success',
              title: 'Success',
              text: message,
            },
            3000
          )
          EventBus.$emit(OrganizationConfig.events.succeededStep4, res.data)
        })
        .catch((err) => {
          // todo: show/handle server errors
          // todo: remove logs
          console.log({ e4: err })
          // Rejected
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
