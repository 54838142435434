<template>
  <!-- start: left-col -->
  <div class="w-full h-full px-4 py-6 bg-white rounded shadow">
    <!-- start: title-section -->
    <div class="flex items-center justify-between">
      <div :class="typographyOne">{{ title }}</div>
      <div v-if="editButton">
        <oto-edit-button variant="gray" @click="handleClicking()" />
      </div>
    </div>
    <!-- end: title-section -->
    <!-- start: footer-section -->
    <div class="info-grid-subheader" :class="subheaderClass">
      <slot
        name="subheader"
        :typographyOne="typographyOne"
        :typographyTwo="typographyTwo"
        :typographyThree="typographyThree"
      ></slot>
    </div>
    <!-- end: footer-section -->

    <!-- start: avatar-section -->
    <template v-if="avatar">
      <display-avatar :avatar="avatar" class="my-6" />
      <div class="active">active</div>
    </template>
    <!-- end: avatar-section -->

    <!-- start: details-section -->
    <div class="grid" :class="gridClass">
      <div class="my-2" v-for="(item, index) in data" :key="index">
        <div class="flex">
          <div class="w-1/2" :class="typographyTwo">
            {{ item.text }}
          </div>
          <div class="w-1/2" :class="typographyThree">
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
    <!-- start: details-section -->

    <!-- start: footer-section -->
    <div class="info-grid-footer" :class="footerClass">
      <slot
        name="footer"
        :typographyOne="typographyOne"
        :typographyTwo="typographyTwo"
        :typographyThree="typographyThree"
      ></slot>
    </div>
    <!-- end: footer-section -->
  </div>
  <!-- end: left-col -->
</template>

<script>
import DisplayAvatar from '@/components/ui/DisplayAvatar'
import OtoEditButton from '@/components/ui/OtoEditButton'

export default {
  name: 'InfoGrid',
  components: { DisplayAvatar, OtoEditButton },
  props: {
    avatar: { required: false, type: String },
    title: { required: false, type: String, default: 'Title' },
    data: { required: false, type: Array },
    editButton: { required: false, type: Boolean, default: true },
    gridClass: { required: false, type: String, default: 'grid-cols-1' },
    footerClass: { required: false, type: String, default: '' },
    subheaderClass: { required: false, type: String, default: '' },
    type: { required: false },
  },
  data() {
    return {
      // title
      typographyOne: 'font-semibold text-oBlack text-19px',
      // subtitle
      typographyTwo: 'font-semibold text-14px text-oBlack',
      // subtitle->value
      typographyThree: 'font-medium text-14px text-oLightGray',
    }
  },
  mounted() {},
  methods: {
    handleClicking() {
      console.log('type = ', this.type)
      this.$emit('edit', this.type)
    },
  },
}
</script>

<style lang="sass">
// .edit-button
//     width: 96px
//     height: 42px
//     padding: 4px 23px 5px 12px
//     border-radius: 30px
//     background-color: #f3f3f3
//     cursor: pointer
</style>
