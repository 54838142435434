export const SubscriptionPlanResource = {
  id: '',
  geofence_categories_service: false,
  geofence_categories_parking: false,
  geofence_categories_slow_zone: false,
  geofence_categories_restricted: false,
  reporting_categories_mds: false,
  reporting_categories_gbfs: false,
  created_at: '',
  updated_at: '',
  plan_name: '',
  status_active: false,
  billing_type: '',
  price: '',
  num_of_vehicles: null,
  num_of_fleets: null,
  num_of_promo_codes: null,
  num_of_org_user_accounts: null,
  multi_currency_enabled: false,
  multi_language_enabled: false,
  end_ride_photo_enabled: false,
  multi_geofence_enabled: false,
  operator_app_enabled: false,
  group_trip_enabled: false,
  rental_enabled: false,
  point_management_enabled: false,
  pass_management_enabled: false,
  ticket_management_enabled: false,
  reporting_enabled: false,
}
