import { CityResource } from './CityResource'
import { CountryResource } from './CountryResource'
import { OwnerUserResource } from './OwnerUserResource'
import { PaymentGatewayResource } from './PaymentGatewayResource'
import { SubscriptionPlanResource } from './SubscriptionPlanResource'

export const OrganizationResource = {
  id: '',
  city: CityResource,
  country: CountryResource,
  default_fleet: null,
  subscription: SubscriptionPlanResource,
  default_payment_gateway_config: PaymentGatewayResource,
  owner_user: OwnerUserResource,
  created_at: '',
  updated_at: '',
  name: '',
  phone_number: '',
  status: '',
  alias: '',
  avatar: '',
  zip_code: '',
  organization_category: '',
  domain: '',
  port: null,
  address: '',
  vehicle_type: [],
  rider_can_select_payment_gateway: false,
  invitation_amount: '',
  user_init_balance: '',
  user_min_balance: '',
  user_balance_threshold: '',
  user_init_points: 0,
  play_store_link: '',
  apple_store_link: '',
  website_link: '',
  email_address: '',
  terms_and_conditions_url: '',
  privacy_policy_url: '',
  about_url: '',
  help_url: '',
  helpline: '',
  default_language: '',
  default_financial_account: '',
}
