export const PaymentGatewayResource = {
  id: '',
  is_enabled: false,
  client_id: '',
  secret_key: '',
  app_key: '',
  extra_key: '',
  payment_gateway: '',
  default_financial_account: '',
}
