export const PaymentGatewayConfig = {
  api: {
    // post [create|org-add-step-3]
    create: '/dashboard/payment-gateway-configs/',
    // post [update|org-edit-step-3]
    update: (id = 'id') => {
      return `/dashboard/payment-gateway-configs/${id}/`
    },
  },
}
